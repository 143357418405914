import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

class FaqsPage extends Component {
  render() {
    const currentPage = this.props.data.wordpressPage
    const pageClass = currentPage.title.replace(/\s+/g, '-').toLowerCase();

    return (
        <Layout>
          <SEO title={currentPage.title} />
          <div className="container">
            <div className="row">
              <div className="span12">
                <section className="title-section">
                  <h1 className="title-header" dangerouslySetInnerHTML={{ __html: currentPage.title }} />
                </section>
                <div className={`page-${pageClass}`}>
                  <dl className="faq-list">
                    {
                      currentPage.acf.sections.map((section, s) => (
                          <>
                            <h3 className={`${s === 0 ? 'first' : ''}`}>{section.title}</h3>
                            {
                              section.faq.map((item, i) => (
                                  <>
                                    <dt className="faq-list_h">
                                      <h4 className="marker">Q?</h4>
                                      <h4>{item.question}</h4>
                                    </dt>
                                    <dd className="faq-list_body">
                                      <h4 className="marker">A.</h4>
                                      <div dangerouslySetInnerHTML={{ __html: item.answer }} />
                                    </dd>
                                  </>
                              ))
                            }
                          </>
                      ))
                    }
                  </dl>
                  <div dangerouslySetInnerHTML={{ __html: currentPage.content }} />
                </div>
              </div>
            </div>
          </div>
        </Layout>
    )
  }
}

export default FaqsPage

export const pageQuery = graphql`
  query {
    wordpressPage(slug: { eq: "faqs" }) {
      title
      content
      date(formatString: "DD MMMM YYYY")
      acf {
        sections {
          title
          faq {
            question
            answer
          }
        }
      }
    }
    site {
      id
      siteMetadata {
        title
        description
      }
    }
  }
`
